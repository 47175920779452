import React, { ReactElement } from 'react';

import Checkbox from 'ui-kit/checkbox/checkbox';

import { FormCheckboxProps } from 'ui-kit/form-checkbox/form-checkbox.props';

const FormCheckbox = (props: FormCheckboxProps): ReactElement => {
    const { form, field, ...rest } = props;
    const handleClick = (): void => {
        if (form && form.setFieldTouched) {
            form.setFieldTouched(field.name, true);
        }
    };
    const handleChange = (isChecked: boolean): void => {
        if (form && form.setFieldValue) {
            form.setFieldValue(field.name, isChecked ? ['on'] : undefined);
        }
    };
    return <Checkbox onClick={handleClick} onCheckChanged={handleChange} {...rest} />;
};

export default FormCheckbox;
