import React, { ReactElement } from 'react';
import { Form, Formik } from 'formik';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import Acknowledgement from 'components/acknowledgement/acknowledgement.component';

import { LOGIN_SCHEMA } from 'schema/login.schema';
import { Login } from 'types/login';

import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';

import Button from 'ui-kit/button/button';
import CtaStack from 'ui-kit/cta-stack/cta-stack';
import Link from 'ui-kit/link/link';
import Text from 'ui-kit/text/text';

import { SignInFormProps } from './sign-in-form.props';

export const SignInUnknownFailureModalContent = ({
    body,
    translation
}: {
    body?: string;
    translation: TFunction<string>;
}) => {
    return (
        <BirdiModalContent
            icon="alert"
            title={translation('modals.signInUnknownFailure.title')}
            body={body ?? translation('modals.signInUnknownFailure.body')}
        />
    );
};

export const SignInLockedFailureModalContent = ({ translation }: { translation: TFunction<string> }) => {
    return (
        <BirdiModalContent
            icon="default"
            title={translation('modals.signInLockedFailure.title')}
            body={translation('modals.signInLockedFailure.body')}
        />
    );
};

const SignInForm = ({ isLoggingIn, onSubmit, style }: SignInFormProps): ReactElement => {
    const { t } = useTranslation();
    const formName = 'SignInForm';

    const handleSubmit = (values: Partial<Login>) => {
        onSubmit(values as Login);
    };

    return (
        <Container fluid style={style}>
            <Row>
                <Col>
                    <Formik<Partial<Login>>
                        onSubmit={handleSubmit}
                        validationSchema={LOGIN_SCHEMA}
                        initialValues={{
                            email: '',
                            password: '',
                            acceptedTos: undefined
                        }}
                    >
                        {(formik: any) => (
                            <Form
                                id="sign-in-form"
                                data-ga-form-name={formName}
                                onSubmit={formik.handleSubmit}
                                autoComplete="off"
                            >
                                <Text
                                    label={t('signIn.labels.email')}
                                    name="email"
                                    maxLength={255}
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.email ? t('signIn.errors.email') : undefined}
                                    touched={formik.touched.email}
                                    value={formik.values?.email}
                                />
                                <Text
                                    label={t('signIn.labels.password')}
                                    name="password"
                                    type="password"
                                    onChange={formik.handleChange}
                                    errors={formik.errors?.password ? t('signIn.errors.password') : undefined}
                                    touched={formik.touched.password}
                                    value={formik.values?.password}
                                />
                                <Acknowledgement
                                    handleChange={formik.handleChange}
                                    handleBlur={formik.handleBlur}
                                    handleErrors={
                                        formik.errors?.acceptedTos ? t(`registration.errors.acceptedTos`) : undefined
                                    }
                                    handleTouched={formik.touched.acceptedTos}
                                />
                                <div className="d-flex flex-column align-items-center pt-4">
                                    <Button
                                        async
                                        label={t('signIn.labels.submit')}
                                        type="submit"
                                        isBusy={isLoggingIn}
                                        dataGAFormName={formName}
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Col>
            </Row>
            <Row>
                <Col>
                    <CtaStack
                        to="/get-started"
                        label={t('signIn.labels.getStartedCta')}
                        eyebrowText={t('signIn.labels.getStartedCtaEyebrowText')}
                    />
                </Col>
            </Row>
            <Row>
                <Col className="text-center mt-2">
                    <Link to="/forgot-password" label="Forgot Password?" variant="subtle" />
                    <Link to="/forgot-username" label="Forgot Username?" variant="subtle" className="ml-4" />
                </Col>
            </Row>
        </Container>
    );
};

export default SignInForm;
