import * as yup from 'yup';

//  validates that both passwords match
export const validateToS = (
    acceptedToS: string[] | undefined,
    context: yup.TestContext<Record<string, any>>
): boolean | yup.ValidationError => {
    const { createError } = context;
    const error = createError({ message: 'acceptedTos' });

    if (!acceptedToS || acceptedToS.length === 0) return error;

    return true;
};
