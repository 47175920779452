import React, { ReactElement, useEffect } from 'react';

import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import BirdiModalContent from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { Field } from 'formik';
import FormCheckbox from 'ui-kit/form-checkbox/form-checkbox';
import { AcknowledgementProps } from './acknowledgement.props';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { useDispatch } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import { paragraphToComponent } from 'providers/paragraphs/paragraphs';
import { setCurrentVid } from 'state/account/account.reducers';
import { getClientIp } from 'const/options';
import storageHelper from 'util/storageHelper';

export const AcknowledgementModalContent = ({ body }: { body: string }) => {
    return <BirdiModalContent body={body} icon={'none'} />;
};

const Acknowledgement = ({
    handleChange,
    handleBlur,
    handleErrors,
    handleTouched
}: AcknowledgementProps): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const pageData = useStaticQuery(graphql`
        query {
            termsOfService: allNodeLandingPage(filter: { path: { alias: { eq: "/website-terms-of-use" } } }) {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    path {
                        langcode
                        alias
                    }
                    drupal_internal__vid
                    relationships {
                        field_landing_page_content {
                            ... on paragraph__rich_text_component {
                                id
                                field_rt_rich_text {
                                    value
                                }
                            }
                        }
                    }
                }
            }
            privacyPolicy: allNodeLandingPage(filter: { path: { alias: { eq: "/notice-of-privacy-practices" } } }) {
                nodes {
                    title
                    id
                    drupal_internal__nid
                    path {
                        langcode
                        alias
                    }
                    drupal_internal__vid
                    relationships {
                        field_landing_page_content {
                            ... on paragraph__rich_text_component {
                                id
                                field_rt_rich_text {
                                    value
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    useEffect(() => {
        dispatch(setCurrentVid(pageData.termsOfService.nodes[0].drupal_internal__vid / 100));
        storageHelper.local.setTosVID((pageData.termsOfService.nodes[0].drupal_internal__vid / 100).toString());
        getClientIp().then((res) => {
            storageHelper.local.setClientIP(res.toString());
        });
    }, []);

    const handleLinkClick = (modalToShow: any) => {
        dispatch(
            openModal({
                showClose: true,
                className: 'scroll-modal',
                bodyContent: (
                    <AcknowledgementModalContent
                        body={modalToShow.nodes[0]?.relationships?.field_landing_page_content.map(
                            (paragraph: any, index: number) => (
                                <React.Fragment key={`acknowledgement_${index}`}>
                                    {paragraphToComponent(paragraph?.__typename, paragraph, index + 10)}
                                </React.Fragment>
                            )
                        )}
                    />
                ),
                ctas: [
                    {
                        label: t('modals.default.submit'),
                        variant: 'primary',
                        onClick: () => {
                            dispatch(closeModal({}));
                        }
                    }
                ]
            })
        );
    };

    return (
        <>
            <Field
                name="acceptedTos"
                component={FormCheckbox}
                className="chk-top"
                label={
                    <Trans i18nKey="registrationTOSCheckboxLink">
                        {'I have read and agree to the '}
                        <span
                            role="button"
                            tabIndex={0}
                            className="text-primary"
                            onClick={() => handleLinkClick(pageData.termsOfService)}
                            onKeyPress={() => handleLinkClick(pageData.termsOfService)}
                        >
                            terms of use
                        </span>
                        {' and I acknowledge I have received the '}
                        <span
                            role="button"
                            tabIndex={0}
                            className="text-primary"
                            onClick={() => handleLinkClick(pageData.privacyPolicy)}
                            onKeyPress={() => handleLinkClick(pageData.privacyPolicy)}
                        >
                            Notice of Privacy Practices of Birdi
                        </span>
                        {'.'}
                    </Trans>
                }
                onChange={handleChange}
                onBlur={handleBlur}
                errors={handleErrors}
                touched={handleTouched}
            />
        </>
    );
};

export default Acknowledgement;
