import React, { ReactElement } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';

import { accountLoginRoutine } from 'state/account/account.routines';
import { accountStateSelector } from 'state/account/account.selectors';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';

import { Login, LoginRequestErrorPayload } from 'types/login';

import SignInForm, {
    SignInLockedFailureModalContent,
    SignInUnknownFailureModalContent
} from 'components/sign-in-form/sign-in-form.component';
import WorkflowLayout from 'components/layouts/workflow/workflow.layout';
import WorkflowLayoutFormWrapper from 'components/workflow-layout-form-wrapper/workflow-layout-form-wrapper.component';

const SignIn = (): ReactElement => {
    const { t } = useTranslation();
    const { isLoggingIn } = useSelector(accountStateSelector);
    const dispatch = useDispatch();

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/bird-on-white-background.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    const onSubmitSignInForm: (login: Login) => void = (login) => {
        dispatch(
            accountLoginRoutine.trigger({
                login,
                onSuccess: () => {
                    // Because the Robin ChatBot cannot re-initialize with the logged-in token,
                    // a hard pageload was requested (DRX-523) instead of a faster "navigate"
                    if (window) {
                        window.location.href = '/secure/medicine-cabinet';
                    } else {
                        navigate('/secure/medicine-cabinet');
                    }
                },
                onFailure: (err: LoginRequestErrorPayload) => {
                    // DEMO: Simulate successful login.
                    if (window) {
                        window.location.href = '/secure/medicine-cabinet';
                    } else {
                        navigate('/secure/medicine-cabinet');
                    }
                    // if (err && err.error === 'user_locked') {
                    //     dispatch(
                    //         openModal({
                    //             showClose: true,
                    //             bodyContent: <SignInLockedFailureModalContent translation={t} />,
                    //             ctas: [
                    //                 {
                    //                     label: t('modals.signInLockedFailure.submit'),
                    //                     variant: 'primary',
                    //                     onClick: () => {
                    //                         dispatch(closeModal({}));
                    //                         navigate('/');
                    //                     }
                    //                 }
                    //             ]
                    //         })
                    //     );
                    // } else {
                    //     dispatch(
                    //         openModal({
                    //             showClose: true,
                    //             bodyContent: (
                    //                 <SignInUnknownFailureModalContent translation={t} body={err.description} />
                    //             ),
                    //             ctas: [
                    //                 {
                    //                     label: t('modals.signInUnknownFailure.submit'),
                    //                     variant: 'primary',
                    //                     onClick: () => {
                    //                         dispatch(closeModal({}));
                    //                     }
                    //                 }
                    //             ]
                    //         })
                    //     );
                    // }
                }
            })
        );
    };

    return (
        <WorkflowLayout
            backgroundImage={imageData.blueSkyBackground}
            useRoundedCorners={false}
            metaData={{ nodeTitle: t('signIn.labels.eyebrowText') }}
        >
            <WorkflowLayoutFormWrapper eyebrowText={t('signIn.labels.eyebrowText')} title={t('signIn.labels.title')}>
                <SignInForm onSubmit={onSubmitSignInForm} isLoggingIn={isLoggingIn} />
            </WorkflowLayoutFormWrapper>
        </WorkflowLayout>
    );
};

export default SignIn;
