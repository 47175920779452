import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import withOnKeyDown from 'hoc/withOnKeyDown';
import { TrackInputFocus } from 'util/google_optimize/optimize_helper';

import { CheckboxProps, CheckboxIconProps } from 'ui-kit/checkbox/checkbox.props';

import './checkbox.style.scss';

const CheckboxSvg = withOnKeyDown(
    ({ defaultChecked, disabled, onCheckChanged, onClick, onFocus, onKeyDown }: CheckboxIconProps<boolean>) => {
        const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
        const classes = classNames('checkbox-svg', { checked: isChecked });

        const handleClick = () => {
            if (disabled) return;
            setIsChecked(!isChecked);
            if (onClick) onClick(!isChecked);
        };

        useEffect(() => {
            if (!disabled && onCheckChanged && typeof isChecked !== 'undefined') onCheckChanged(isChecked);
        }, [isChecked]);

        useEffect(() => {
            setIsChecked(defaultChecked);
        }, [defaultChecked]);

        return (
            <svg
                viewBox="0 0 100 100"
                className={classes}
                onClick={handleClick}
                onFocus={onFocus}
                onKeyDown={(e: React.KeyboardEvent<HTMLOrSVGElement>) =>
                    onKeyDown ? onKeyDown(e, handleClick) : undefined
                }
                role="button"
                tabIndex={0}
            >
                <path
                    className="box"
                    d="M82,89H18c-3.87,0-7-3.13-7-7V18c0-3.87,3.13-7,7-7h64c3.87,0,7,3.13,7,7v64C89,85.87,85.87,89,82,89z"
                />
                <polyline className="check" points="25.5,53.5 39.5,67.5 72.5,34.5 " />
            </svg>
        );
    }
);

const Checkbox = ({ id, className, defaultValue, disabled, errors, onCheckChanged, label, touched }: CheckboxProps) => {
    const [isChecked, setIsChecked] = useState<boolean | undefined>(false);
    const classes = classNames('checkbox-container', className, { disabled }, { checked: isChecked });

    const handleClick = (checked?: boolean) => {
        if (disabled) return;
        setIsChecked(checked);
    };
    const handleFocus = (event: FocusEvent) => {
        TrackInputFocus(event.target, label);
    };

    useEffect(() => {
        setIsChecked(defaultValue);
    }, [defaultValue]);

    return (
        <div className={classes}>
            <div className="checkbox">
                <CheckboxSvg
                    id={id}
                    disabled={disabled}
                    defaultChecked={Boolean(defaultValue)}
                    onClick={handleClick}
                    onCheckChanged={onCheckChanged}
                    onFocus={handleFocus}
                />
                <label className="checkbox-label">{label}</label>
            </div>
            {touched && errors && <span className="checkbox-errors">{errors}</span>}
        </div>
    );
};

export default Checkbox;
